import React, { useState } from 'react';
import TextArea from './TextArea';
import { AppBar, Button, Container, Toolbar, Typography } from '@mui/material';

const App = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleEncodeClick = () => {
    const encoded = btoa(unescape(encodeURIComponent(input)));
    setOutput(encoded);
  };

  const handleDecodeClick = () => {
    const decoded = decodeURIComponent(escape(atob(input)));
    setOutput(decoded);
  };

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: '#111', zIndex: 9999 }}>
        <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Base64{' '}
          <Typography variant="subtitle2" component="div" sx={{ fontSize: '70%', color: '#888', marginTop: '-0.3em' }}>
            miny
          </Typography>
        </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" sx={{ marginTop: 10 }}>
        <Typography variant="body1" sx={{ marginBottom: 2, textAlign: 'center', color: '#111' }}>
          Paste below, encode or decode Base64.
        </Typography>
        <TextArea
          label="Input"
          value={input}
          onChange={handleInputChange}
          InputProps={{ sx: { backgroundColor: '#f9fafb' } }}
          placeholder="Paste your base64 string here..."
        />
        <Button variant="contained" onClick={handleEncodeClick} sx={{ mt: 2, mr: 2, mb: 2 }}>
          Encode
        </Button>
        <Button variant="contained" onClick={handleDecodeClick} sx={{ mt: 2,  mb: 2 }}>
          Decode
        </Button>
        <TextArea
          label="Output"
          value={output}
          onChange={() => {}}
          InputProps={{ sx: { backgroundColor: '#f9fafb' } }}
          sx={{ marginTop: 2 }}
          placeholder="Result will appear here..."
        />
      </Container>
    </>
  );
};

export default App;
